import { template as template_31f84cc63aad4e1b83ddc64d737d838e } from "@ember/template-compiler";
const WelcomeHeader = template_31f84cc63aad4e1b83ddc64d737d838e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
