import { template as template_df5e381b1649407f987e6db11bd74076 } from "@ember/template-compiler";
const FKControlMenuContainer = template_df5e381b1649407f987e6db11bd74076(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
