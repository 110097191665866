import { template as template_157f4902b0d24e7687d7b2748835c47e } from "@ember/template-compiler";
const FKLabel = template_157f4902b0d24e7687d7b2748835c47e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
